@import "./Assets/css/_utitilies.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  width: 50%;
}

body {
  background: #f2f6fc;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-login-image {
  background: url(./Assets/images/futuristic_hud.svg);
  background-position: center;
  background-size: cover;
}

.auth-card {
  padding-top: 3rem;
}

.img-account-profile {
  height: 10rem;
}

.sb-sidenav-menu-nested {
  margin-left: 1.4375rem;
  border-left-style: solid;
  border-left-width: thin;
  padding-left: 0.5625rem;
  border-left-color: #d4dae3;
}

.div-disabled {
  pointer-events: none;
  opacity: 0.4;
}
