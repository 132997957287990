.vh-20 {
  height: 20vh !important;
}
.min-vh-50 {
  min-height: 50vh !important;
}
.top-40 {
  top: 40% !important;
}
.mh-icon-20 {
  max-height: 20rem;
}
.mh-icon-25 {
  max-height: 25rem;
}
